
import { faqMapActions } from "@/store/modules/faq";
import {
  atlasPlatformMapActions,
  atlasPlatformMapState,
  atlasPlatformMapGetters
} from "@/store/modules/atlasPlatform";
import VueWithMixins from "@/helpers/mixins";
import LiveChatMixin from "@/components/LiveChatMixin";
import AppLoading from "@/views/public/Loading.vue";
import { IAtlasPlatform } from "@/store/modules/atlasPlatform/types";

interface IOption {
  label: string;
  value: string;
}

interface IData {
  errorMessage: string;
  activeName: string;
  tabPosition: string;
  showExtraInfo: boolean;
  atlasPlatformsSearchOptions: IOption[];
  initialFetch: boolean;
  localAtlasPlatforms: IOption[];
}

export default VueWithMixins(LiveChatMixin).extend({
  name: "FAQ",
  components: {
    AppLoading
  },
  data(): IData {
    return {
      errorMessage: "",
      activeName: "",
      tabPosition: "left",
      showExtraInfo: true,
      atlasPlatformsSearchOptions: [],
      localAtlasPlatforms: [],
      initialFetch: false
    };
  },
  mounted: async function() {
    this.fetchAtlasPlatforms();
  },
  computed: {
    ...atlasPlatformMapState(["makingApiRequest"]),
    ...atlasPlatformMapGetters(["atlasPlatforms"]),
    getPlatforms(): IAtlasPlatform[] {
      return this.atlasPlatforms;
    }
  },
  methods: {
    ...faqMapActions(["getFAQs"]),
    ...atlasPlatformMapActions(["getAtlasPlatforms"]),
    async searchFAQ(search: string[]) {
      await this.fetchAtlasPlatforms(search);
    },
    isActive(faqIndex: string | number) {
      return this.activeName === faqIndex;
    },
    async fetchAtlasPlatforms(name?: string[] | undefined) {
      try {
        await this.getAtlasPlatforms({
          getQuestions: true,
          deleted: false,
          name
        });

        this.atlasPlatformsSearchOptions = this.getPlatforms.map(
          atlasPlatform => {
            return {
              label: atlasPlatform.name,
              value: atlasPlatform.name
            };
          }
        );
        if (!this.initialFetch) {
          this.localAtlasPlatforms = this.atlasPlatformsSearchOptions;
        }
        this.initialFetch = true;
      } catch (error) {
        this.$bugSnagClient.notify(error);
      }
    },

    showExtraInfoHandler() {
      this.showExtraInfo = false;
      this.liveChatIsActive = false;
    },
    showLiveChatHandler() {
      this.setUpChatLive(this.$getCurrentUser ?? {}, true);
    }
  }
});
